import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import useFields from './fields';
import { CreateUpdateContainer } from '../../../components';
import useAuthContext from '../../../contexts/AuthContext';

const CreateUpdateCustomer = ({ purpose }) => {
  const { fields, suggestion, datas_date, default_iban, steps } = useFields(
    purpose
  );
  const { user } = useAuthContext();

  const config = {
    onGetResource: {
      setFields: data => ({
        ...data,
        dob: data.dob && moment(data.dob)
      })
    },
    onCreateResource: {
      setBody: data => {console.log('data', data);return({
        ...data,
        ref_seller: user._id,
        phone_number: data.phone_number.country_code + data.phone_number.number
      })}
    }
  };

  return (

    <CreateUpdateContainer
      fields={fields}
      purpose={purpose}
      baseUrl="customers"
      resource="customers"
      config={config}
      updatedValues={suggestion} /* For autocomplete purpose */
      datasDate={datas_date}
      ibanDatas={default_iban}
      steps={steps} />
  );
};

CreateUpdateCustomer.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateCustomer;
