import React from 'react';
import { useTranslation } from 'react-i18next';
import SimpleResourceLandingLayout from '../../components/layouts/SimpleResourceLandingLayout';
import useAuthContext from '../../contexts/AuthContext';

const ListManagerAdvisors = () => {
  const { t } = useTranslation();
  const { user, checkShouldDisplayListItem } = useAuthContext();

  const columns = [
    {
      title: t('users.form.last_name'),
      key: 'last_name',
      dataIndex: 'last_name',
      render: null,
      sorter: true
    },
    {
      title: t('users.form.first_name'),
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: true
    },
    {
      title: t('users.form.email'),
      key: 'email',
      dataIndex: 'email',
      render: null,
      sorter: true
    },
    {
      title: t('users.form.phone_number_full'),
      key: 'phone_number',
      dataIndex: 'phone_number',
      sorter: true
    }
  ];

  return (
    <SimpleResourceLandingLayout
      resourceName="manager-advisors"
      columns={columns.filter(column =>
        checkShouldDisplayListItem('manager-advisors', column.key)
      )}
      resourceModelName="MANAGER-ADVISORS"
      withUploadButton={false}
      worth_styling
    />
  );
};

export default ListManagerAdvisors;
