import React from 'react';

const boxShadowContainer =
	'0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d';

const styles_advisor = {
	app_divider_container_descBenef: {
		width: '130px',
		height: '3px',
		position: 'absolute',
		background: 'var(--primaryColor)',
		borderRadius: '10px',
		top: '3.5rem',
		left: '1.5rem'
	},
	app_divider_container_extra_large: {
		width: '150px',
		height: '3px',
		background: 'var(--primaryColor)',
		marginRight: 'auto',
		marginTop: '-0.5rem',
		borderRadius: '10px'
	},
	app_divider_container_contract_benefits: {
		width: '70px',
		height: '3px',
		position: 'absolute',
		background: 'var(--primaryColor)',
		borderRadius: '10px',
		top: '3.5rem',
		left: '1.5rem'
	},
	app_divider_container_self_perf: {
		width: '60px',
		height: '3px',
		position: 'absolute',
		background: 'var(--primaryColor)',
		borderRadius: '10px',
		top: '2rem',
		left: '0rem'
	},
	app_divider_container_perf: {
		width: '100px',
		height: '3px',
		position: 'absolute',
		background: 'var(--primaryColor)',
		borderRadius: '10px',
		top: '2rem',
		left: '0rem'
	},
	app_divider_container_self_terminate: {
		width: '80px',
		height: '3px',
		position: 'absolute',
		background: 'var(--primaryColor)',
		borderRadius: '10px',
		top: '2rem',
		left: '0rem'
	}
};
export { styles_advisor };
