import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { Button, Popconfirm, Skeleton, Row, Divider, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined,
  PlusOutlined,
  EyeOutlined
} from '@ant-design/icons';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import managerAdvisorContent from './listContent';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../components';
import { routes, subRoutes } from '../../utils/constants/routes';

const ShowCustomer = () => {
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [managerAdvisor, setManagerAdvisor] = useState({});
  const [userIdManagerAdvisor, setUserIdManagerAdvisor] = useState();
  const iconSize = 18;

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `${routes['MANAGER-ADVISORS']}/${id}?populate=advisors`
      });
      setManagerAdvisor(data);
      const getUserManagerAdvisor = await dispatchAPI('GET', {
        url: `/manager-advisors/getUserId/${id}`
      });
      setUserIdManagerAdvisor(getUserManagerAdvisor.data._id);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, [id]);

  const deleteManagerAdvisor = async () => {
    try {
      await dispatchAPI('DELETE', {
        url: `${routes['MANAGER-ADVISORS']}/${id}`
      });
      history.goBack();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const deleteResource = async key => {
    try {
      await dispatchAPI('DELETE', { url: `/advisors/${key}` });
      await fetchData();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      if (e.response) message(e.response.status);
    }
  };

  const columns = [
    {
      title: t('users.form.last_name'),
      key: 'last_name',
      dataIndex: 'last_name',
      render: null,
      sorter: true
    },
    {
      title: t('users.form.first_name'),
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: true
    },
    {
      title: t('users.form.email'),
      key: 'email',
      dataIndex: 'email',
      render: null,
      sorter: true
    },
    {
      title: t('users.form.phone_number_full'),
      key: 'phone_number',
      dataIndex: 'phone_number',
      sorter: true
    }
  ];
  const actionColumn = {
    key: 'action',
    // eslint-disable-next-line react/prop-types
    render: ({ key }) => (
      <div style={{ float: 'right' }}>
        <Link to={`/advisors/show/${key}`}>
          <EyeOutlined style={{ fontSize: iconSize }} />
        </Link>
        <Divider type="vertical" />
        <Link to={`/advisors/edit/${key}`} replace>
          <EditOutlined style={{ fontSize: iconSize }} />
        </Link>
        <Divider type="vertical" />
        <Popconfirm
          title={t('datatable.column.action.delete.title')}
          okText={t('datatable.column.action.delete.ok')}
          okButtonProps={{ type: 'danger' }}
          cancelText={t('datatable.column.action.delete.cancel')}
          onConfirm={() => deleteResource(key)}
          icon={<WarningOutlined />}
        >
          <DeleteOutlined
            style={{ color: 'red', fontSize: iconSize }}
            type="delete"
          />
        </Popconfirm>
      </div>
    )
  };

  const getDatasource = () => {
    console.log(managerAdvisor);
    const result = (managerAdvisor.advisors || []).map(advisor => ({
      ...advisor,
      key: advisor._id
    }));
    return result;
  };

  return (
    <>
      <PageHeaderCustom
        title={t('manager-advisors.show.title')}
        extra={
          <>
            <Link
              to={{
                pathname: `${routes['MANAGER-ADVISORS']}/edit/${id}`
              }}
            >
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteManagerAdvisor}
              icon={<WarningOutlined />}
            >
              <Button type="link" danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentCustom>
        <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
          <DescriptionList
            data={managerAdvisorContent(managerAdvisor || {})}
            translate
          />
        </Skeleton>
        <Divider orientation="left" style={{ marginTop: 48 }}>
          {t('manager-advisors.show.listAdvisors')}
        </Divider>

        <Row gutter={[0, 16]} justify="end">
          <Button
            onClick={() =>
              history.push(
                `/advisors/create?from=direction&${userIdManagerAdvisor}`
              )
            }
            type="primary"
          >
            {t('buttons.create')}
            <PlusOutlined />
          </Button>
        </Row>
        <Table
          dataSource={getDatasource()}
          columns={[...columns, actionColumn]}
          onRow={({ key }) => ({
            onDoubleClick: () => history.push(`/advisors/show/${key}`)
          })}
        />
      </ContentCustom>
    </>
  );
};

export default ShowCustomer;
