import React, { useCallback, useEffect, useState } from 'react';
import { Button, Tag } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useAuthContext from '../../contexts/AuthContext';
import { invoiceStatuses } from '../../utils/constants/tagColors';
import useErrorMessage from '../../utils/ErrorMessage';
import { routes } from '../../utils/constants/routes';

export const useColumns = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const message = useErrorMessage();
  const [enums, setEnums] = useState();

  const getEnums = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/invoices/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, [getEnums]);

  return [
    {
      title: 'Date de facturation',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true,
      render: (date) => (date ? moment(date).format('DD/MM/YYYY') : '-')
    },
    {
      title: 'Numéro de facture',
      dataIndex: 'title',
      key: 'title',
      sorter: true
    },
    {
      title: 'Statut de paiement',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={invoiceStatuses[status]}>
          {t(`invoices.tags.${status}`)}
        </Tag>
      ),
      sorter: true,
      filters: enums?.statuses?.map((status) => ({
        text: (
          <Tag color={invoiceStatuses[status]}>
            {t(`invoices.tags.${status}`)}
          </Tag>
        ),
        value: status
      }))
    },
    {
      title: 'Date de paiement',
      dataIndex: 'payment_date',
      key: 'payment_date',
      sorter: true,
      render: (date) => (date ? moment(date).format('DD/MM/YYYY') : '-')
    },
    {
      title: 'Client',
      dataIndex: 'customer',
      key: 'customer',
      render: (customer) =>
        customer ? (
          <Link to={`${routes.CONTRACTS}/show/${customer.contract}`}>
            <Button type="link">{`${customer.first_name} ${customer.last_name}`}</Button>
          </Link>
        ) : (
          '-'
        )
    }
  ];
};
