import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import {
  Button,
  Popconfirm,
  Skeleton,
  Row,
  Avatar,
  Divider,
  Form,
  InputNumber,
  message
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined,
  ShopOutlined
} from '@ant-design/icons';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import moment from 'moment';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import DescriptionList from '../../components/DescriptionList/DescriptionList';
import listContent from './listContent';
import ContentCustom from '../../components/ContentCustom/ContentCustom';
import PageHeaderCustom from '../../components/PageHeader/PageHeader';
import { routes } from '../../utils/constants/routes';
import {
  styles,
  ProfileSession,
  ObjectiveSession,
  TerminatedSession,
  ContainerBody,
  AnimatedPhone
} from './styles.jsx';
import { BarChart } from '../home/charts_folder/BarChart';
import { ReactComponent as AdvisorIcon } from './extraIcons/AdvisorIcon.svg';
import './extraIcons/styles.css';

const ShowAdvisor = () => {
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [record, setRecord] = useState();
  const onFinish = async values => {
    const { data } = await dispatchAPI('POST', {
      url: `/goals/`,
      body: {
        ref_seller: record,
        date: moment().toDate(),
        performanceFixed: parseInt(values.goal)
      }
    });
    message.success('Le nouvel objectif a été défini avec succès');
  };

  const getUser = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/advisors/${id}`
      });
      setUser(data);
      const matchingId = await dispatchAPI('GET', {
        url: `/home_advisor/getAdvisorId/${id}`
      });
      setRecord(matchingId.data._id);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  const deleteUser = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/advisors/${id}` });
      history.goBack();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const [datas_goal, setDatas_goal] = useState([]);
  const [datas_terminated, setDatas_terminated] = useState([]);
  const fields = [
    'Résiliés',
    'Rien du tout',
    "Attribution d'avantage",
    "Changement d'offre"
  ];
  const [goal, setGoal] = useState(0);
  const [form] = Form.useForm();

  useEffect(() => {
    const getData = async key => {
      const { data } = await dispatchAPI('GET', {
        url: `/home_advisor/getDetailedTerminated/${key}`
      });
      setDatas_goal(data);
      const terminated = await dispatchAPI('GET', {
        url: `/home_advisor/getDetailedOffer/${key}`
      });
      setDatas_terminated(terminated.data);
    };
    if (record) {
      getData(record);
    }
  }, [record]);

  const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleChange = e => {
    if (typeof e === 'number') {
      setGoal(e);
    } else {
      setGoal(e.target.value);
    }
  };

  const cols_histo = {
    value: {
      nice: false,
      min: 0,
      tickInterval: 1
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={t('sellers.show.title')}
        is_home={true}
        extra={
          <>
            <Link to={{ pathname: `${routes.SELLERS}/edit/${id}` }}>
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteUser}
              icon={<WarningOutlined />}
            >
              <Button type="link" type="danger">
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentCustom ContentCustom background="none !important">
        <div
          style={{
            maxWidth: '1400px',
            margin: 'auto',
            display: 'flex',
            width: '100%'
          }}
        >
          <ProfileSession>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Avatar
                className="avatar_advisor"
                shape="square"
                size={150}
                style={{ backgroundColor: '#fde3cf' }}
                icon={<AdvisorIcon style={{ color: '#f56a007d' }} />}
              ></Avatar>
            </div>
            <div style={{ width: '80%', margin: 'auto' }}>
              <Divider />
            </div>
            <Skeleton active loading={isLoading} paragraph={{ rows: 1 }}>
              <p style={{ textAlign: 'center', marginBottom: '0.25rem' }}>
                <span style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
                  {user &&
                    `${capitalizeFirstLetter(
                      user.last_name
                    )}  ${capitalizeFirstLetter(user.first_name)}`}
                </span>
              </p>
              <div
                style={{
                  display: 'flex',
                  flexFlow: 'column',
                  justifyContent: 'center',
                  color: 'gray',
                  alignItems: 'center'
                }}
              >
                <p style={{ marginBottom: '0rem' }}>
                  <span>
                    {user && formatPhoneNumberIntl(user.phone_number)}
                  </span>
                </p>
                <p style={{ marginBottom: '0rem' }}>
                  <span>
                    {user &&
                      `En activité depuis ${moment(user.begin_date).format(
                        'MMMM YYYY'
                      )} `}
                  </span>
                </p>
              </div>
            </Skeleton>
          </ProfileSession>
          <ContainerBody>
            <ObjectiveSession>
              <div style={styles.graph_header}>
                <p style={styles.graph_header_first}>
                  Taux de transformation des demandes de résiliations
                </p>
                <p style={styles.graph_header_second}>{`~ ${moment().format(
                  'YYYY'
                )}`}</p>
              </div>
              <div style={styles.app_divider_container_extra_large}></div>
              <div style={{ marginTop: '1rem' }}>
                <BarChart
                  data={datas_goal}
                  xAxis="name"
                  yAxis="terminate"
                  fields={fields}
                  type="intervalStack"
                  height={300}
                  from_manager={true}
                  has_special_name="Nombre d'appels"
                  developingFields="Appels"
                />
              </div>
            </ObjectiveSession>
            <TerminatedSession>
              <div style={styles.graph_header}>
                <p style={styles.graph_header_first}>Activités</p>
                <p style={styles.graph_header_second}></p>
              </div>
              <div style={styles.app_divider_container_small}></div>
              <div style={{ marginTop: '1rem' }}>
                <BarChart
                  data={datas_terminated}
                  xAxis="name"
                  yAxis="hours"
                  fields={cols_histo}
                  type="interval"
                  height={100}
                  is_simple={true}
                  is_histogram={true}
                />
              </div>
            </TerminatedSession>
          </ContainerBody>
        </div>
      </ContentCustom>
    </>
  );
};

export default ShowAdvisor;
