import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import moment from 'moment';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ReactComponent as GoldIcon } from '../home_icons/GoldIcon.svg';
import { ReactComponent as PlatIcon } from '../home_icons/PlatIcon.svg';
import { styles } from '../styles_home/home_seller.js';
import { styles_advisor } from '../styles_home/home_advisor.js';
// Import Swiper React components

// Import Swiper styles
import 'swiper/swiper.scss';
import '../../../assets/styles/SCSS/comparisonTable.scss';
import '../../../assets/styles/SCSS/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import {
  Row,
  Col,
  Divider,
  Tooltip,
  Tag,
  Button,
  Switch,
  Table,
  Radio,
  Modal,
  AutoComplete,
  message,
  Input,
  Spin,
  Space
} from 'antd';

import { ReactComponent as ContractIcon } from '../home_icons/ContractIcon.svg';
import { ReactComponent as TerminatedContract } from '../home_icons/TerminatedContract.svg';
import { ReactComponent as GoalIcon } from '../home_icons/GoalIcon.svg';
import { Donut_chart } from '../charts_folder/Donut_chart';
import { Line_chart } from '../charts_folder/Line_chart';
import { BarChart } from '../charts_folder/BarChart';
import { Two_axis_chart } from '../charts_folder/Two_axis_chart';
import '../../../assets/styles/SCSS/tableHome.scss';
import useAuthContext from '../../../contexts/AuthContext';
import { motion } from 'framer-motion';
import ComparisonTableDynamic from '../components_home/ComparisonTableDynamic';
import TableContractsBenefits from '../components_home/TableContractsBenefits';
import SearchDatas from '../components_home/SearchDatas';
import ad1 from '../images/woman_sized.jpg';
import ad2 from '../images/gift_sized.png';
import {
  Divider_header,
  Graph_container,
  Major_graph_container,
  LineGraph,
  TabHeader,
  SearchContainer,
  ContentBenefitGold,
  ContentBenefitPlat,
  ThTable,
  TdTable,
  DonutChart,
  FirstContainerAdvisor,
  AdvantagesTable,
  ContractTable,
  SecondContainerAdvisor,
  ProfitContainer,
  ResiliateContainer,
  Graph_containerAdvisor,
  Graph_containerAdvisorB,
  CarouselContainer,
  HeaderCarousel,
  DescriptionCarousel
} from '../components_home/ExtraComponents';
import { useStateWithLocalStorage } from '../../../utils';
import ResiliateModal from '../../Contracts/Contracts/ResiliateModal';
import useHandleResize from '../../../utils/HandleResize';

const HomeManagerAdvisor = () => {
  SwiperCore.use([Navigation, Autoplay]);
  const [searchResults, setSearchResults] = useState([]);
  const history = useHistory();
  const { dispatchAPI, user } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [datas_benefits, setDatas_benefits] = useState([]);
  const [startingBenefit, setStartingBenefit] = useState([]);
  const [datas_contracts, setDatas_contracts] = useState([]);
  const [triggerAdd, setTriggerAdd] = useStateWithLocalStorage(
    'triggerAdd',
    false
  );
  const [datas_activityContract, setDatas_activityContract] = useState([]);
  const [datas_resiliated, setDatas_resiliated] = useState([]);
  const [list_benefit, setList_benefit] = useState([]);

  const { width } = useHandleResize();

  const routesNames = [
    `/getSearchResults/`,
    `/getBenefits/`,
    `/getLastContracts/`,
    `/getActivityContracts/`,
    `/getFrequencySided/`
  ];
  const routesNamesAdvisor = ['/getBenefitList/'];
  const getDatas = useCallback(async () => {
    try {
      for (let index = 0; index < routesNames.length; index++) {
        const { data } = await dispatchAPI('GET', {
          url: `/home_seller${routesNames[index]}`
        });
        if (index === 0) {
          setSearchResults(data);
        } else if (index === 1) {
          setStartingBenefit(data[data.length - 1]);
          setDatas_benefits(data.slice(0, -1));
        } else if (index === 2) {
          setDatas_contracts(data);
        } else if (index === 3) {
          setDatas_activityContract(data);
        } else if (index === 4) {
          setDatas_resiliated(data);
        }
      }
      for (let index = 0; index < routesNamesAdvisor.length; index++) {
        const { data } = await dispatchAPI('GET', {
          url: `/home_advisor${routesNamesAdvisor[index]}`
        });
        if (index === 0) {
          setList_benefit(data);
        }
      }
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
    setIsLoading(true);
  }, [user._id]);

  useEffect(() => {
    getDatas();
  }, [getDatas]);

  const cols_donuts = {
    percent: {
      formatter: val => (val = `${val * 100}%`)
    }
  };

  const fields = [
    'Jan',
    'févr',
    'Mars',
    'Avr',
    'Mai',
    'Juin',
    'Jul',
    'Août',
    'Sept',
    'Oct',
    'Nov',
    'Déc'
  ];
  const lightBoxShadow =
    'rgb(0 0 0 / 12%) 0px 3px 6px -4px, rgb(0 0 0 / 8%) 0px 6px 16px, rgb(0 0 0 / 5%) 0px 9px 28px 8px';

  const SampleContainerSearch = styled.div`
    flex: 1.02;
    @media screen and (max-width: 1500px) {
      flex: none;
    }
  `;

  return (
    <>
      {!isLoading ? (
        <div
          style={{
            padding: '20px',
            marginTop: '0rem',
            paddingTop: '2rem',
            maxWidth: '1600px',
            margin: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh'
          }}
        >
          <Spin tip="Veuillez patienter..." size="large" />
        </div>
      ) : (
        <div
          style={{
            padding: '20px',
            marginTop: '0rem',
            paddingTop: '2rem',
            maxWidth: '1600px',
            margin: 'auto'
          }}
        >
          <motion.div
            animate={{ opacity: [0, 1] }}
            transition={{ duration: 0.1, delay: 0.5 }}
          >
            <CarouselContainer>
              <Swiper
                spaceBetween={50}
                slidesPerView={1}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false
                }}
              >
                <SwiperSlide>
                  <div
                    style={{
                      display: 'flex',
                      background: 'white',
                      alignItems: 'center',
                      height: '150px'
                    }}
                  >
                    <img
                      src={ad1}
                      style={{
                        flex: 0.5,
                        alignSelf: 'end',
                        display: width < 550 ? 'none' : 'initial'
                      }}
                    />
                    <div
                      style={{
                        padding: width < 768 ? '1rem' : '2rem',
                        flex: 2,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexDirection: width < 768 ? 'column' : 'row'
                      }}
                    >
                      <div>
                        <HeaderCarousel>
                          <span style={{ color: 'var(--primaryColor)' }}>
                            Évoluez
                          </span>{' '}
                          dans un monde connecté
                        </HeaderCarousel>
                        <DescriptionCarousel>
                          1 tablette Samsung ou Apple gagnée par contrat Gold
                        </DescriptionCarousel>
                      </div>
                      <Link to="/customers/create?from=dashboard">
                        <Button style={styles.carousel_button} type="primary">
                          Je découvre
                        </Button>
                      </Link>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    style={{
                      display: 'flex',
                      background: 'white',
                      alignItems: 'center',
                      height: '150px'
                    }}
                  >
                    <img
                      src={ad2}
                      style={{
                        flex: 0.5,
                        alignSelf: 'end',
                        display: width < 550 ? 'none' : 'initial'
                      }}
                    />
                    <div
                      style={{
                        padding: width < 768 ? '1rem' : '2rem',
                        flex: 2,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexDirection: width < 768 ? 'column' : 'row'
                      }}
                    >
                      <div>
                        <HeaderCarousel>
                          <span style={{ color: '#ebb300' }}>Fnac</span>, c'est
                          bon d'être curieux
                        </HeaderCarousel>
                        <DescriptionCarousel>
                          1 tablette Samsung ou Apple gagnée par contrat Gold
                        </DescriptionCarousel>
                      </div>
                      <Link to="/customers/create?from=dashboard">
                        <Button style={styles.carousel_button} type="primary">
                          Je découvre
                        </Button>
                      </Link>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    style={{
                      display: 'flex',
                      background: 'white',
                      alignItems: 'center',
                      height: '150px'
                    }}
                  >
                    <img
                      src={ad1}
                      style={{
                        flex: 0.5,
                        alignSelf: 'end',
                        display: width < 550 ? 'none' : 'initial'
                      }}
                    />
                    <div
                      style={{
                        padding: width < 768 ? '1rem' : '2rem',
                        flex: 2,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexDirection: width < 768 ? 'column' : 'row'
                      }}
                    >
                      <div>
                        <HeaderCarousel>
                          Avec <span style={{ color: '#ac0c7d' }}>SNCF</span>,
                          il y a toujours un train sur qui compter
                        </HeaderCarousel>
                        <DescriptionCarousel>
                          1 tablette Samsung ou Apple gagnée par contrat Gold
                        </DescriptionCarousel>
                      </div>
                      <Link to="/customers/create?from=dashboard">
                        <Button style={styles.carousel_button} type="primary">
                          Je découvre
                        </Button>
                      </Link>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </CarouselContainer>
          </motion.div>
          <motion.div
            animate={{ width: ['0%', '100%'], opacity: [0, 1] }}
            transition={{ duration: 0.1, delay: 0.1 }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <SearchContainer className="Search_container">
                <SearchDatas
                  searchResults={searchResults}
                  placeholder="Rechercher des clients, des contrats"
                />
              </SearchContainer>
              <SampleContainerSearch />
            </div>
          </motion.div>
          <motion.div
            animate={{ y: -20, opacity: [0, 1] }}
            transition={{ duration: 0.1 }}
          >
            <FirstContainerAdvisor>
              <AdvantagesTable>
                <ComparisonTableDynamic
                  datas_benefits={datas_benefits}
                  startingBenefit={startingBenefit}
                />
              </AdvantagesTable>
              <ContractTable>
                <TableContractsBenefits datas_contracts={datas_contracts} />
              </ContractTable>
            </FirstContainerAdvisor>
          </motion.div>
          <motion.div
            animate={{ y: -20, opacity: [0, 1], delay: 12 }}
            transition={{ duration: 0.1, delay: 0.05 }}
          >
            <Major_graph_container>
              <LineGraph>
                <Graph_containerAdvisor>
                  <div style={styles.graph_header}>
                    <p style={styles.graph_header_first}>
                      Activité des contrats
                    </p>
                    <p style={styles.graph_header_second} />
                  </div>
                  <div style={styles.app_divider_container_line} />
                  <div style={{ cursor: 'pointer' }}>
                    <BarChart
                      data={datas_resiliated}
                      xAxis="month"
                      yAxis="terminate"
                      fields={fields}
                      type="intervalStack"
                      height={300}
                    />
                  </div>
                </Graph_containerAdvisor>
              </LineGraph>
              <DonutChart>
                <Graph_containerAdvisorB>
                  <div style={styles.graph_header}>
                    <p style={styles.graph_header_first}>Taux de résiliation</p>
                    <p style={styles.graph_header_second} />
                  </div>
                  <div style={styles.app_divider_container_donut} />
                  <div style={{ cursor: 'pointer' }}>
                    <Donut_chart
                      data={datas_activityContract}
                      xAxis="item"
                      yAxis="percent"
                      cols={cols_donuts}
                    />
                  </div>
                </Graph_containerAdvisorB>
              </DonutChart>
            </Major_graph_container>
          </motion.div>
        </div>
      )}
    </>
  );
};

export default HomeManagerAdvisor;
