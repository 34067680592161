import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext
} from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import {
  ArrowRightOutlined,
  ArrowLeftOutlined,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
  CheckOutlined,
  PlusOutlined
} from '@ant-design/icons';
import {
  Tag,
  Button,
  Table,
  Radio,
  Modal,
  message,
  Input,
  Spin,
  Space,
  Popconfirm,
  Form,
  InputNumber,
  Dropdown,
  Menu
} from 'antd';
import PropTypes from 'prop-types';
import {
  SearchContainer,
  ContentBenefitGold,
  ContentBenefitPlat,
  ComparisonTable,
  ThTable,
  TdTable,
  ButtonComparisonGold,
  ButtonComparisonPlat
} from './ExtraComponents';
import { styles_advisor } from '../styles_home/home_advisor.js';
import { styles } from '../styles_home/home_seller.js';
import { useStateWithLocalStorage } from '../../../utils';
import '../../../assets/styles/SCSS/comparisonTable.scss';
import useAuthContext from '../../../contexts/AuthContext';

const ComparisonTableDynamic = ({ startingBenefit, is_direction }) => {
  const { TextArea } = Input;
  const { dispatchAPI, user } = useAuthContext();
  const [offersGot, setOffersGot] = useState();
  const [count, setCount] = useState();
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    let inputNode;
    inputNode =
      inputType === 'number' ? <InputNumber /> : <TextArea maxLength={100} />;
    return (
      <td {...restProps}>
        {editing
          ? (inputType !== 'number' && (
          <Form.Item
                name={dataIndex}
                style={{
                  margin: 0
                }}
                rules={[
                  {
                    required: true,
                    message: `veuillez spécifier ${
                      !title
                        ? "un nom d'avantage"
                        : "une description d'avantage"
                    }!`
                  }
                ]}
              >
                {inputNode}
              </Form.Item>
            )) || (
              <Form.Item
                name={dataIndex}
                style={{
                  margin: 0
                }}
              >
                {inputNode}
              </Form.Item>
            )
          : children}
      </td>
    );
  };

  const getDatas = useCallback(async () => {
    const { data } = await dispatchAPI('GET', {
      url: `/home_advisor/getBenefitList/`
    });
    setOffersGot(data);
    setCount(data.length);
  }, [user._id]);

  useEffect(() => {
    getDatas();
  }, [getDatas]);

  const isEditing = record => record.key === editingKey;

  const edit = record => {
    form.setFieldsValue({
      title: record.title ? record.title.split('///')[0] : '',
      descriptionPlat: record.descriptionPlat
        ? record.descriptionPlat.split('///')[0]
        : '',
      descriptionGold: record.descriptionGold
        ? record.descriptionGold.split('///')[0]
        : '',
      eligibility:
        record.eligibility !== '-' ? record.eligibility.split('///')[0] : null
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const handleDelete = async key => {
    await dispatchAPI('DELETE', {
      url: `home_advisor/${key.key.split('///')[1]}`
    });
    setCount(count - 1);
    const newDatas_contracts = [
      ...offersGot.filter(item => item.key !== key.key)
    ];
    setOffersGot(newDatas_contracts);
    message.success(
      `l'avantage '${key.title.split('///')[0]}' a été supprimé avec succès !`
    );
  };
  const handleAdd = async () => {
    setCount(count + 1);
    const { data } = await dispatchAPI('POST', {
      url: '/home_advisor/postBenefitList/',
      body: {
        key: count + 1
      }
    });
    const newData = {
      ...data
    };
    setOffersGot([...offersGot, newData]);
    message.success('Un nouvel avantage a été ajouté');
  };

  const save = async key => {
    try {
      const row = await form.validateFields();
      const newData = [...offersGot];
      const index = newData.findIndex(item => key === item.key);
      const { data } = await dispatchAPI('PATCH', {
        url: `home_advisor/${key.split('///')[1]}`,
        body: {
          ...row,
          key: key.split('///')[1]
        }
      });
      if (row.eligibility) {
        row.eligibility += '///mois';
      } else {
        row.eligibility = '-';
      }
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setOffersGot(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setOffersGot(newData);
        setEditingKey('');
      }
      if (row) {
        message.success('Les données ont bien été modifiées');
      }
    } catch (errInfo) {
      message.error('Error:', errInfo);
    }
  };

  const lightBoxShadow =
    'rgb(0 0 0 / 12%) 0px 3px 1px -4px, rgb(0 0 0 / 8%) 0px 0px 16px, rgb(0 0 0 / 3%) 0px 9px 29px 7px';

  const columns = [
    {
      title: '',
      dataIndex: 'title',
      render: text => {
        let data;
        if (text) data = text.split('///');
        return (
          <>
            {data
              ? (data[1] && data[1].length && (
              <div>
                    <div>
                  <p
                        style={{
                          marginBottom: '0rem',
                          maxWidth: '85%',
                          whiteSpace: 'break-spaces'
                        }}
                      >
                        {data[0]}
                      </p>
                  <p>{data[1]}</p>
                </div>
                  </div>
                )) || (
                  <div>
                    <p
                      style={{
                        marginBottom: '0rem',
                        maxWidth: '85%',
                        whiteSpace: 'break-spaces',
                        fontWeight: 'bold'
                      }}
                    >
                      {data[0]}
                    </p>
                  </div>
                )
              : null}
          </>
        );
      },
      responsive: ['md', 'lg'],
      width: '15%',
      editable: true
    },
    {
      title: () => {
        return (
          <div>
            <p style={{ marginBottom: '0rem', fontSize: '1rem' }}>Platinium</p>
            <p style={{ marginBottom: '0rem' }}>
              <span style={{ fontWeight: 300 }}>Dès </span>
              <span style={{ fontSize: '1.2rem' }}> 
{' '}
{startingBenefit[1]}€</span>
              <span style={{ fontWeight: 300 }}>/mois</span>
            </p>
          </div>
        );
      },
      dataIndex: 'descriptionPlat',
      editable: true,
      render: text => {
        let data;
        if (text) data = text.split('///');
        return (
          <>
            {data && (
              <div className="platinium">
                <p
                  style={{
                    marginBottom: '0rem',
                    maxWidth: '70%',
                    whiteSpace: 'break-spaces'
                  }}
                >
                  {data[0]}
                </p>
                <p style={styles.p_benefit}>
                  ~ 
{' '}
{parseInt(data[1]) ? data[1] : 0} bénéficiaire
                  {parseInt(data[1]) > 1 && 's'}
                </p>
              </div>
            )}
          </>
        );
      }
    },
    {
      title: () => {
        return (
          <div>
            <p style={{ marginBottom: '0rem', fontSize: '1rem' }}>Gold</p>
            <p style={{ marginBottom: '0rem' }}>
              <span style={{ fontWeight: 300 }}>Dès </span>
              <span style={{ fontSize: '1.2rem' }}> 
{' '}
{startingBenefit[0]}€</span>
              <span style={{ fontWeight: 300 }}>/mois</span>
            </p>
          </div>
        );
      },
      dataIndex: 'descriptionGold',
      editable: true,
      render: text => {
        let data;
        if (text) data = text.split('///');
        return (
          <>
            {data && (
              <div className="gold">
                <p
                  style={{
                    marginBottom: '0rem',
                    maxWidth: '70%',
                    whiteSpace: 'break-spaces'
                  }}
                >
                  {data[0]}
                </p>
                <p style={styles.p_benefit}>
                  ~ 
{' '}
{parseInt(data[1]) ? data[1] : 0} bénéficiaire
                  {parseInt(data[1]) > 1 && 's'}
                </p>
              </div>
            )}
          </>
        );
      }
    },
    {
      title: () => {
        return (
          <>
            <p style={{ marginBottom: '2.5rem', fontSize: '0.9rem' }}>
              Eligibilité
            </p>
          </>
        );
      },
      dataIndex: 'eligibility',
      editable: true,
      width: '15%',
      render: text => (text !== 'undefined' ? `${text} Jours` : '-')
    } /*,
    {
      title: '',
      dataIndex: 'operation',
      width: '15%',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button
              onClick={() => save(record.key)}
              style={{
                marginRight: 8
              }}
              type="add"
              shape="circle"
              icon={<CheckOutlined />}
            />
            <Popconfirm title="vraiment annuler?" onConfirm={cancel}>
              <Button type="danger" shape="circle" icon={<CloseOutlined />} />
            </Popconfirm>
          </span>
        ) : (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="0">
                  <a onClick={() => edit(record)}>Editer</a>
                </Menu.Item>
                <Menu.Item key="1">
                  <Popconfirm
                    title="Êtes vous sûr de vouloir supprimer l'avantage ?"
                    onConfirm={() => handleDelete(record)}
                  >
                    <a>supprimer</a>
                  </Popconfirm>
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <div>
              <div
                style={{
                  width: '5px',
                  height: '5px',
                  background: '#d9d9d9',
                  borderRadius: '50%'
                }}
              />
              <div
                style={{
                  width: '5px',
                  height: '5px',
                  background: '#d9d9d9',
                  borderRadius: '50%',
                  marginTop: '0.15rem',
                  marginBottom: '0.15rem'
                }}
              />
              <div
                style={{
                  width: '5px',
                  height: '5px',
                  background: '#d9d9d9',
                  borderRadius: '50%'
                }}
              />
            </div>
          </Dropdown>
        );
      }
    }*/
  ];
  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: record => ({
        record,
        inputType: col.dataIndex === 'eligibility' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record)
      })
    };
  });

  return (
    <>
      <div style={styles.graph_header}>
        <p style={styles.graph_header_first}>
          Description des types de contrats
        </p>
        {/* is_direction && (
          <div style={{ marginTop: '-1rem' }}>
            <Button
              type="primary"
              onClick={handleAdd}
              style={{ boxShadow: lightBoxShadow }}
            >
              Ajouter
              <PlusOutlined />
            </Button>
          </div>
        ) */}
      </div>
      <div style={styles_advisor.app_divider_container_descBenef} />
      <ContentTable>
        <ComparisonTable className="comparisonTableBenefit">
          <Form form={form} component={false}>
            <Table
              bordered={false}
              // components={{
              //   body: {
              //     cell: EditableCell
              //   }
              // }}
              // rowClassName={(record, index) =>
              //   index % 2 === 0
              //     ? 'editable-row'
              //     : 'editable-row table-row-light'
              // }
              dataSource={offersGot}
              columns={mergedColumns}
              // onRow={(record, rowIndex) => ({
              //   onDoubleClick: () =>
              //     editingKey === '' ? edit(record) : save(record.key)
              // })}
              pagination={false}
              scroll={{ y: 230 }}
            />
          </Form>
        </ComparisonTable>
      </ContentTable>
    </>
  );
};

const ContentTable = styled.div`
  margin-top: 0.5rem;
  height: 340px;
  overflow: hidden;
  min-width: 500px;
  width: fit-content;
  @media (min-width: 850px) {
    max-width: 100%;
  }
`;

ComparisonTableDynamic.propTypes = {
  startingBenefit: PropTypes.array,
  is_direction: PropTypes.bool
};

ComparisonTableDynamic.defaultProps = {
  startingBenefit: null,
  is_direction: false
};

export default ComparisonTableDynamic;
