import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Layout, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import ForgotPwdForm from './ForgotPwdForm';
import useHandleResize from '../../utils/HandleResize';
import ChangePwdForm from './ChangePwdForm';
import Logo from '../../assets/images/logostrategin.png';
import loginBg1 from '../../assets/images/login1.jpg';
import loginBg2 from '../../assets/images/login2.jpg';
import loginBg3 from '../../assets/images/login3.jpg';
import loginBg4 from '../../assets/images/login4.jpg';
import loginBg5 from '../../assets/images/login5.jpg';
import { UpdateAlert } from '../../components';

const Login = () => {
  const { token } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const { Title } = Typography;
  const { width, height } = useHandleResize();
  const [currentForm, setCurrentForm] = useState('login');
  const [code, setCode] = useState('');
  const forms = {
    login: <LoginForm switchForm={f => setCurrentForm(f)} />,
    register: <RegisterForm switchForm={f => setCurrentForm(f)} />,
    forgotPwd: <ForgotPwdForm switchForm={f => setCurrentForm(f)} />,
    changePwd: (
      <ChangePwdForm switchForm={f => setCurrentForm(f)} token={token} />
    )
  };
  const { location } = history;

  const imgArray = [loginBg1, loginBg2, loginBg3, loginBg4, loginBg5];

  // Selects a random image to display
  const getRandomImage = () => {
    const randInd = Math.floor(Math.random() * imgArray.length);
    return imgArray[randInd];
  };

  if (location.search) {
    const searchString = location.search.split('?')[1];
    const searchParts = searchString.split('&');

    searchParts.forEach(part => {
      const partMap = part.split('=');

      if (partMap[0] === 'code') {
        const newCode = partMap[1];
        if (code === '') setCode(newCode);
      }
    });
  }

  useEffect(() => {
    if (token) setCurrentForm('changePwd');
  }, [token]);

  return (
    <div>
      <UpdateAlert />
      <Layout
        style={{
          minHeight: '100vh',
          maxHeight: '100vh',
          minWidth: '100vw',
          maxWidth: '100vw',
          overflow: 'hidden',
          display: 'inherit'
        }}
      >
        <Row>
          <Col
            xs={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
            style={{ backgroundColor: 'var(--componentBackground)' }}
          >
            <Row
              justify={width < 993 ? 'center' : 'start'}
              align="middle"
              style={{
                height: '100vh',
                maxHeight: '100vh',
                backgroundImage: `url(${getRandomImage()})`,
                backgroundPosition: width < 993 ? 'center' : 'right 60%',
                overflow: 'hidden',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
              }}
            >
              <Col
                xxl={7}
                xl={9}
                lg={12}
                md={14}
                sm={16}
                xs={24}
                style={{
                  height: '100vh',
                  maxHeight: '100vh',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: height < 600 ? 'flex-start' : 'center',
                  textAlign: 'center',
                  padding: width < 993 ? '20px 32px' : 60,
                  background:
                    width < 576
                      ? 'rgba(255, 255, 255, 0.4)'
                      : 'rgba(255, 255, 255, 0.6)',
                  backdropFilter: width < 993 ? 'blur(2px)' : 'blur(4px)',
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  position: width < 993 ? 'inherit' : 'fixed',
                  left: width < 993 ? 'inherit' : '11vw'
                }}
              >
                {width > 768 && (
                  <img
                    style={{
                      width: 320,
                      marginBottom: 120,
                      alignSelf: 'center'
                    }}
                    alt="Logo"
                    src={Logo}
                  />
                )}
                {width <= 768 && height > 550 && (
                  <img
                    alt="logo"
                    style={{
                      width: '75%',
                      maxWidth: '320px',
                      marginBottom: 90,
                      alignSelf: 'center'
                    }}
                    src={Logo}
                  />
                )}
                {width <= 768 && height <= 550 && (
                  <img
                    alt="logo"
                    style={{
                      width: '60%',
                      maxWidth: '320px',
                      marginBottom: 40,
                      alignSelf: 'center'
                    }}
                    src={Logo}
                  />
                )}
                <Title
                  style={{
                    textAlign: 'center',
                    marginBottom: 40,
                    fontSize: 40
                  }}
                >
                  {t(`login.title.${currentForm}`)}
                </Title>
                {forms[currentForm]}
              </Col>
            </Row>
          </Col>
        </Row>
      </Layout>
    </div>
  );
};

Login.propTypes = {
  location: PropTypes.shape({}).isRequired
};

export default Login;
