import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import useFields from './fields';
import CreateUpdateContainer from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import useAuthContext from '../../contexts/AuthContext';

const CreateUpdateSeller = ({ purpose }) => {
  const { user, dispatchAPI } = useAuthContext();
  const { fields } = useFields(purpose);
  const { id } = useParams();

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const [store, setStore] = useState('La direction');
  const [partner, setPartner] = useState(user._id);

  useEffect(() => {
    const getStore = async () => {
      let data_seller;
      if (user._id) {
        if (user.role !== 'admins:DIRECTION') {
          if (user.role === 'users:MANAGER') {
            data_seller = await dispatchAPI('GET', {
              url: `/home_manager/getStoreFromManager/${user._id}`
            });
          } else if (user.role === 'users:PARTNER') {
            data_seller = await dispatchAPI('GET', {
              url: `/home_manager/getStoreFromPartner/${user._id}`
            });
          }
          if (data_seller) {
            setStore(capitalizeFirstLetter(data_seller.data.title));
            setPartner(data_seller.data.ref_partner);
          }
        }
      }
    };
    getStore();
  }, []);

  const config = {
    onGetResource: {
      setFields: async (data) => {
        const res = await dispatchAPI('GET', {
          url: `/partners?email=${data.ref_partner.email}`
        });

        return {
          ref_manager: data.title,
          ref_partner: res.data[0].name,
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          phone_number: data.phone_number,
          date_of_birth: data.date_of_birth && moment(data.date_of_birth)
        };
      }
    },

    onCreateResource: {
      setBody: (data) => {
        let seller;
        if (user.role !== 'admins:DIRECTION') {
          seller = {
            ...data,
            title: store,
            ref_manager: user._id,
            ref_partner: partner._id
          };
        } else {
          const {
            ref_partner,
            ref_manager,
            first_name,
            last_name,
            email,
            phone_number
          } = { ...data };
          seller = {
            ref_partner,
            ref_manager: ref_manager?.split('-')[0],
            first_name,
            last_name,
            email,
            phone_number,
            title: ref_manager?.split('-')[1]
          };
        }
        return seller;
      }
    }
  };

  return (
    <CreateUpdateContainer
      fields={fields}
      purpose={purpose}
      baseUrl="sellers"
      resource="sellers"
      config={config}
      populate="ref_partner"
    />
  );
};

CreateUpdateSeller.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateSeller;
