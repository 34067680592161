import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { message, Row, Col, Select, Skeleton } from 'antd';
import { DatePicker, Space } from 'antd';
import { motion } from 'framer-motion';
import { styles } from '../styles_home/home_seller';
import moment from 'moment';
import '../../../assets/styles/SCSS/tableHome.scss';
import useAuthContext from '../../../contexts/AuthContext';
import SearchDatas from '../components_home/SearchDatas';
import {
  Major_graph_container as MajorGraphContainer,
  LineGraph,
  SearchContainer,
  DonutChart as DonutChartStyled,
  Graph_containerAdvisor as GraphContainerAdvisor,
  Graph_containerAdvisorB as GraphContainerAdvisorB
} from '../components_home/ExtraComponents';

import ListStores from '../components_home/ListStores';
import ListTerminate from '../components_home/ListTerminate';
import ChartBiz from '../../../components/Charts/Chart';
import TerminatedChart from '../charts_folder/TerminatedChart';

const { Option } = Select;
const { RangePicker } = DatePicker;

const DashboardLineGraph = ({
  partners,
  filterData,
  handleChangePartner,
  dateRange,
  setDateRange
}) => {
  const dateFormat = 'YYYY/MM/DD';
  return (
    <MajorGraphContainer style={{ width: '100%' }}>
      <LineGraph>
        <GraphContainerAdvisor>
          <div style={styles.graph_header}>
            <p style={styles.graph_header_first}>{`Contrats`}</p>
            <div>
              {'Magasins : '}
              <Select
                defaultValue="Tous"
                style={{ width: 200 }}
                onChange={handleChangePartner}
              >
                <Option value="all">Tous</Option>
                {partners?.map(partner => (
                  <Option value={partner.title}>{partner.title}</Option>
                ))}
              </Select>
            </div>
            <Space direction="vertical" size={12}>
              <RangePicker
                defaultValue={[
                  moment(dateRange[0], dateFormat),
                  moment(dateRange[1], dateFormat)
                ]}
                onChange={val => setDateRange(val)}
                format={'DD/MM/YYYY'}
                allowClear={false}
              />
            </Space>
          </div>
          <div style={styles.app_divider_container_line} />
          <div style={{ cursor: 'pointer', marginTop: '30px' }}>
            <ChartBiz contractStats={filterData} />
          </div>
        </GraphContainerAdvisor>
      </LineGraph>
    </MajorGraphContainer>
  );
};

const DashboardPieGraph = ({ filterData }) => {
  return (
    <MajorGraphContainer style={{ width: '100%', height: '100%' }}>
      <DonutChartStyled style={{ width: '100%', height: '100%' }}>
        <GraphContainerAdvisorB>
          <div style={styles.graph_header}>
            <p style={styles.graph_header_first}>{`Taux de résiliation`}</p>
            <p style={styles.graph_header_second} />
          </div>
          <div style={styles.app_divider_container_donut} />
          <div style={{ cursor: 'pointer', marginTop: "20px" }}>
            <TerminatedChart terminated={filterData} />
          </div>
        </GraphContainerAdvisorB>
      </DonutChartStyled>
    </MajorGraphContainer>
  );
};

const Home_advisor = () => {
  const [searchResults, setSearchResults] = useState([]);
  const { dispatchAPI, user } = useAuthContext();

  const [datas_contracts, setDatas_contracts] = useState([]);
  const [datas_resiliated, setDatas_resiliated] = useState([]);
  const { Option } = Select;

  const routesNames = [
    `/getSearchResults/`,
  ];

  const getDatas = useCallback(async () => {
    try {
      for (let index = 0; index < routesNames.length; index++) {
        const { data } = await dispatchAPI('GET', {
          url: `/home_direction${routesNames[index]}`
        });
        if (index === 0) setSearchResults(data);
      }
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  }, [user._id]);

  useEffect(() => {
    getDatas();
  }, [getDatas]);

  const SampleContainerSearch = styled.div`
    flex: 1.02;
    @media screen and (max-width: 1500px) {
      flex: none;
    }
  `;

  const [loading, setLoading] = useState(false);
  const [filterData, setFilterDataStats] = useState([]);
  const [filterDataTab, setFilterDataTab] = useState([]);
  const [partner, setPartner] = useState('all');
  const [partners, setPartners] = useState([]);
  const [dateRange, setDateRange] = useState([
    moment().format('YYYY-MM'),
    moment().format('YYYY-MM-DD')
  ]);

  const getStats = async () => {
    setLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/contracts/stats/stat?ref_partner=${
          user._id
        }&title=${partner}&start_date=${moment(dateRange[0]).format(
          'YYYY-MM-DD'
        )}&end_date=${moment(dateRange[1]).format('YYYY-MM-DD')}`
      });
      setFilterDataStats(data);
      setLoading(false);
    } catch (error) {
      if (error.response) message.error(error.response.status);
    }
  };

  const rationMonth = (lastMonth, previousMonth) => {
    let perct = 0;
    previousMonth > 0 && previousMonth > 0
      ? (perct = ((lastMonth / previousMonth) * 100).toFixed(2))
      : (perct = 0);
    return Number(perct);
  };

  const getStatsTab = async () => {
    setLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/contracts/stats/tab?ref_partner=${
          user._id
        }&title=${partner}&start_date=${moment(dateRange[0]).format(
          'YYYY-MM-DD'
        )}&end_date=${moment(dateRange[1]).format('YYYY-MM-DD')}`
      });
      setFilterDataTab(data);
      let resil = [];
      data.map(item => {
        resil.push({
          name: item.name,
          value: rationMonth(item.terminated, item.lastMonth)
        });
      });
      setDatas_resiliated(resil);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getPartners = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/stores/?ref_partner=${user._id}`
      });
      setPartners(data);
    } catch (error) {
      console.log(error);
    }
  };

  const applyFilter = useCallback(async () => {
    await getStats();
    await getStatsTab();
  }, [partner, dateRange]);

  useEffect(() => {
    (async () => {
      await getPartners();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await applyFilter();
    })();
  }, [applyFilter]);

  const handleChangePartner = partner => {
    setPartner(partner);
  };

  return (
    <>
      {
        <div
          style={{
            padding: '20px',
            marginTop: '0rem',
            paddingTop: '2rem',
            maxWidth: '1600px',
            margin: 'auto'
          }}
        >
          <motion.div
            animate={{ opacity: [0, 1] }}
            transition={{ duration: 0.5, delay: 0.05 }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <SearchContainer className="Search_container">
                <SearchDatas
                  searchResults={searchResults}
                  placeholder="Rechercher des clients, des contrats, des vendeurs, des partenaires, etc..."
                />
              </SearchContainer>
              <SampleContainerSearch />
            </div>
          </motion.div>

          <Row justify="space-evenly" style={{ width: '100%' }}>
            {loading ? (
              <Skeleton active />
            ) : (
              <>
                <Col span={24}>
                  <DashboardLineGraph
                    partners={partners}
                    filterData={filterData}
                    dateRange={dateRange}
                    handleChangePartner={handleChangePartner}
                    setDateRange={setDateRange}
                  />
                </Col>{' '}
                <Col span={24}>
                  <div style={{ width: '100%' }}>
                    {filterDataTab && <ListStores data={filterDataTab} />}
                  </div>
                </Col>
                <Col span={12}>
                  {datas_resiliated && <DashboardPieGraph filterData={datas_resiliated} />}

                </Col>
                <Col span={12} style={{ padding: 16 }}>
                  {filterDataTab && <ListTerminate data={filterDataTab} />}
                </Col>
              </>
            )}
          </Row>
        </div>
      }
    </>
  );
};

export default Home_advisor;
