import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  Row,
  Col,
  Divider,
  Tooltip,
  Tag,
  Button,
  Switch,
  Table,
  Radio,
  Modal,
  AutoComplete,
  message,
  Input,
  Spin,
  Space
} from 'antd';
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { styles } from '../styles_home/home_seller.js';
import useAuthContext from '../../../contexts/AuthContext';
import { styles_advisor } from '../styles_home/home_advisor.js';
import ResiliateModal from '../../Contracts/Contracts/ResiliateModal';
import RatingForm from '../../Contracts/Contracts/RatingForm';
import CreateUpdateRequest from '../../Requests/CreateUpdateRequest';
import CreateUpdateContract from '../../Contracts/Contracts/CreateUpdateContract';

const TableContractsBenefits = ({ datas_contracts }) => {
  const history = useHistory();
  const location = useLocation();
  const [selectionType, setSelectionType] = useState('checkbox');
  const [searchText, setSearchText] = useState();
  const [searchedColumn, setSearchedColumn] = useState();
  const [label_resiliate, setLablel_resiliate] = useState('Résilier');
  const [hasSelected, setHasSelected] = useState(false);
  const [data_selected, setData_selected] = useState(null);
  const [shadowButton, setShadowButton] = useState('none');
  const searchInput = useRef();
  const { dispatchAPI, user } = useAuthContext();
  const [contracts, setContracts] = useState(datas_contracts);
  const [refSeller, setRefSeller] = useState();
  const [customer, setCustomer] = useState();
  const [loaderElement, setLoaderElement] = useState();

  const lightBoxShadow =
    'rgb(0 0 0 / 12%) 0px 3px 6px -4px, rgb(0 0 0 / 8%) 0px 6px 16px, rgb(0 0 0 / 5%) 0px 9px 28px 8px';

  const handleSearchTable = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchedColumn(dataIndex);
    setSearchText(selectedKeys[0]);
  };

  const terminateContract = async data_selected => {
    const id = data_selected[0].key;
    try {
      const diff = moment().diff(moment(data_selected[0].true_date), 'hours');
      showModal(id);
      setRefSeller(data_selected[0].ref_seller);
      setCustomer(data_selected[0].customer_id);
    } catch (e) {
      if (e.response) message.error(e.response);
    }
  };

  const truncate = (str, n) => {
    return str.length > n ? `${str.substr(0, n - 1)}...` : str;
  };

  const handleResetTable = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          type="primary"
          ref={node => {
            searchInput.current = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearchTable(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearchTable(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            type="primary"
            onClick={() => handleResetTable(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current.focus(), 100);
      }
    }
  });

  const columns = [
    {
      title: 'Durée',
      dataIndex: 'date',
      render: text => {
        const table_datas = text.split('///');
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p style={{ marginBottom: '0rem', fontWeight: 500 }}>
              {table_datas[0]}
            </p>
            <p
              style={{
                marginBottom: '0rem',
                fontSize: '13px',
                color: '#949393'
              }}
            >
              {table_datas[1]}
            </p>
          </div>
        );
      },
      responsive: ['md'],
      ...getColumnSearchProps('date')
    },
    {
      title: 'Client',
      dataIndex: 'customer',
      render: text => {
        const table_datas = text.split('///');
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p style={{ marginBottom: '0rem', fontWeight: 500 }}>
              {table_datas[0]}
            </p>
            <p
              style={{
                marginBottom: '0rem',
                fontSize: '13px',
                color: '#949393'
              }}
            >
              De {truncate(table_datas[1], 20)}
            </p>
          </div>
        );
      },
      ...getColumnSearchProps('customer')
    },
    {
      title: 'Description',
      dataIndex: 'description',
      render: text => {
        const table_datas = text.split('///');
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Tag
              color={
                table_datas[0] === 'Gold'
                  ? 'rgb(204 161 69)'
                  : 'rgb(183, 183, 183)'
              }
              style={{
                marginBottom: '0rem',
                fontWeight: 500,
                width: 'fit-content'
              }}
            >
              {table_datas[0]}
            </Tag>
            <p
              style={{
                marginBottom: '0rem',
                fontSize: '13px',
                color: '#949393'
              }}
            >
              {table_datas[1]}
            </p>
          </div>
        );
      },
      ...getColumnSearchProps('description')
    }
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      if (selectedRowKeys && selectedRowKeys.length > 0) {
        const diff = moment().diff(moment(selectedRows[0].true_date), 'hours');
        setLablel_resiliate('Demande de résiliation');
        setHasSelected(true);
        setData_selected(selectedRows);
        setShadowButton(lightBoxShadow);
      } else {
        setHasSelected(false);
        setShadowButton('none');
        setLablel_resiliate('Demande de résiliation');
      }
    }
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [id_contract, setId_contract] = useState();
  let reason_terminate;
  const handleCancel = () => {
    setIsModalVisible(false);
    setLoaderElement();
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const showModal = (id, ref_seller, customerID) => {
    setIsModalVisible(true);
    setId_contract(id);
    setRefSeller(ref_seller);
    setCustomer(customerID);
  };

  useEffect(() => {
    (async () => {
      const params = location.search.split('?')[1];
      const objectParams = new URLSearchParams(params);
      const keys = objectParams.keys();
      const values = objectParams.values();
      const outputKeys = [];
      const outputValue = [];
      for (const value of values) {
        outputValue.push(value);
      }
      for (const key of keys) {
        outputKeys.push(key);
      }
      if (outputValue.length !== 0) {
        if (outputValue[0] === 'resiliation_completed') {
          let body;
          const reasonStory = 'resiliate';
          if (outputValue[1]) {
            body = {
              ref_user: user._id,
              ref_contract: id_contract,
              message: outputValue[1],
              reason: reasonStory
            };
          } else {
            body = {
              ref_user: user._id,
              ref_contract: id_contract,
              reason: reasonStory,
              message: '-'
            };
          }
          await dispatchAPI('POST', { url: `/story/`, body });
          setLoaderElement();
        }
        if (outputValue[0] === 'resiliation_failed') {
          if (outputValue[1] === '3') {
            // The advisor convinced the user to not resiliate it's contract, rather to allocate one of it's benefits
            setLoaderElement(
              <CreateUpdateRequest purpose="create" id_benefit={id_contract} />
            );
          } else if (outputValue[1] === '4') {
            message.success("Le changement d'offre a été établi avec succès !");
            handleCancel();
          }
        }
      }
      if (outputKeys.length !== 0) {
        if (outputKeys[0] === 'rating_completed') {
          message.success('Le vote a été bien pris en compte !');
        }
        if (
          outputKeys[0] === 'rating_completed' ||
          outputKeys[0] === 'rating_not_completed'
        ) {
          message.success(
            'La demande de résiliation a été établie avec succès !'
          );
          handleCancel();
        }
      }
      if (outputValue.length || outputKeys.length) {
        history.replace();
      }
    })();
  }, [location]);

  return (
    <>
      <div style={styles.graph_header}>
        <p style={styles.graph_header_first}>Derniers contrats</p>
        <div style={styles.graph_header_second}>
          <Button
            type="danger"
            style={{ boxShadow: shadowButton }}
            onClick={() => terminateContract(data_selected)}
            disabled={!hasSelected}
          >
            {label_resiliate}
          </Button>
        </div>
      </div>
      <div style={styles_advisor.app_divider_container_contract_benefits} />
      <div
        style={{
          marginTop: '1.5rem',
          minHeight: 'fit-content',
          width: 'fit-content',
          minWidth: '100%'
        }}
      >
        <Table
          className="table_contracts"
          style={{ maxHeight: '100%', width: '100%' }}
          rowSelection={{
            type: selectionType,
            ...rowSelection
          }}
          rowClassName="rowTableHome"
          columns={columns}
          pagination={false}
          dataSource={datas_contracts}
          onRow={({ key }) => ({
            onClick: () =>
              history.push({
                pathname: `contracts/show/${key}`
              })
          })}
        />
      </div>
      <Modal
        title="Demande de résiliation"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        width={800}
        className="modalBenefit"
      >
        {loaderElement || (
          <ResiliateModal
            id_contract={id_contract}
            ref_seller={refSeller}
            ref_customer={customer}
          />
        )}
      </Modal>
    </>
  );
};

TableContractsBenefits.propTypes = {
  datas_contracts: PropTypes.array
};

TableContractsBenefits.defaultProps = {
  datas_contracts: null
};

export default TableContractsBenefits;
